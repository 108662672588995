<template>
	<div>
		
		<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16" key="company">
				
			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-10">
					<span class="alert-icon">!</span>
					<span class="">{{ getFormError(form) }}</span>
				</div>
			</template>

			<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						leftIcon="person-outline"
						name="name"
						:value="firstName"
						:disabled="true"
					>
						First Name
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						leftIcon="person-outline"
						name="name"
						:value="lastName"
						:disabled="true">
						Last Name
					</form-group>
				</div>
			
				<div class="col-span-1">
					<form-group
						type="email"
						leftIcon="mail-outline"
						name="email"
						:value="email"
						:disabled="true"
					>
						Email Address
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						type="date"
						leftIcon="calendar-outline"
						name="date_of_birth"
						:form="form"
						v-model="form.data.date_of_birth.value"
					>
						Date Of Birth
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						type="select"
						:options="genders"
						leftIcon="transgender-outline"
						name="gender"
						:form="form"
						v-model="form.data.gender.value"
					>
						Gender
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						type="select"
						:options="maritalStatuses"
						leftIcon="body-outline"
						name="marital_status"
						:form="form"
						v-model="form.data.marital_status.value"
					>
						Marital Status
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						type="phone"
						leftIcon="call-outline"
						name="phone_no"
						:form="form"
						v-model="form.data.phone_no.value"
					>
						Phone Number
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						leftIcon="lock-closed-outline"
						name="bvn"
						:form="form"
						:value="form.data.bvn.value"
						@change="value => form.data.bvn.value = bvnAlreadyProvided ? form.data.bvn.value : value"
						:disabled="bvnAlreadyProvided"
					>
						BVN
					</form-group>
				</div>

				<div class="col-span-1" v-if="isEmployed">
					<form-group
						type="select"
						:options="employmentTypes"
						leftIcon="briefcase-outline"
						name="emp_type"
						:form="form"
						v-model="form.data.emp_type.value"
					>
						Employment Type
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="money"
						leftIcon="wallet-outline"
						name="salary"
						:form="form"
						v-model="form.data.salary.value"
					>
						Salary
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						leftIcon="briefcase-outline"
						name="position"
						:form="form"
						v-model="form.data.position.value"
					>
						Job Title
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="daysOfTheMonth"
						select-display-type="grid"
						:select-grid-columns="4"
						leftIcon="calendar-outline"
						name="salary_day"
						:disabled="salaryAlreadyProvided"
						:value="form.data.salary_day.value"
						@change="value => form.data.salary_day.value = salaryAlreadyProvided ? form.data.salary_day.value : value"
						:form="form"
					>
						Salary Day
					</form-group>
				</div>

				<div class="col-span-1">
					<form-group
						leftIcon="wallet-outline"
						name="account_no"
						:form="form"
						v-model="form.data.account_no.value"
					>
						Account Number
					</form-group>

					<div v-if="bankAccountNeedsVerification" class="flex items-center mb-6 -mt-3 text-orange-500 text-sm">
						<span class="alert-icon border border-orange-500 mr-2">
							<ion-icon name="alert-outline"></ion-icon>
						</span>
						<span>"<span class="font-bold">{{ trialBankAccountNumber }}</span>" Unverified</span>
					</div>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="bankOptions"
						leftIcon="business-outline"
						name="bank_name"
						:form="form"
						v-model="form.data.bank_name.value"
						:loading="banksLoading"
					>
						Bank Name
					</form-group>

					<div v-if="bankAccountNeedsVerification" class="flex items-center mb-6 -mt-3 text-orange-500 text-sm">
						<span class="alert-icon border border-orange-500 mr-2">
							<ion-icon name="alert-outline"></ion-icon>
						</span>
						<span>"<span class="font-bold">{{ trialBank }}</span>" Unverified</span>
					</div>
				</div>

				<!-- <div class="col-span-1">
          <form-group
            type="select"
            :options="idTypes"
            leftIcon="reader-outline"
            name="id_type"
            :form="form"
            v-model="form.data.id_type.value">
            ID Type
          </form-group>
        </div> -->
        <div class="col-span-1">
          <form-group
            leftIcon="document-text-outline"
            name="nin"
            :form="form"
            :value="form.data.nin.value"
            @change="value => form.data.nin.value = ninAlreadyProvided ? form.data.nin.value : value"
            :disabled="ninAlreadyProvided"
          >
            NIN
          </form-group>
        </div>

        <div class="md:col-span-1">
          <form-group
            type="text" 
            leftIcon="location-outline" 
            name="address" 
            :form="form"
            v-model="form.data.address.value"
          >
            Home Address
          </form-group>
        </div>

				<div class="col-span-1">
					<form-group
						type="select"
						:options="states"
						leftIcon="reader-outline"
						name="state"
						:form="form"
						v-model="form.data.state.value"
					>
						State
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						type="select"
						:options="lgas"
						leftIcon="reader-outline"
						name="lga"
						:form="form"
						v-model="form.data.lga.value"
					>
						LGA
					</form-group>
				</div>
				
				<div class="col-span-1 md:col-span-2">
					<form-group 
						type="text" 
						leftIcon="location-outline" 
						name="company_address" 
						:form="form"
						v-model="form.data.company_address.value"
						>
							Company Address
					</form-group>
				</div>	

				<div class="col-span-1 md:col-span-2" v-if="additionalPhoneProvided">
					<form-group 
						type="text" 
						leftIcon="location-outline"
						v-model="additionalPhone"
						:disabled="true"
					>
						Additional Phone
					</form-group>
				</div>
			</div>

			<div class="text-right">
				<button
					type="submit"
					class="btn btn-blue"
					:disabled="form.loading"
				>
					<span v-if="form.loading">Updating</span>
					<span v-else>Update Profile</span>
				</button>
			</div>

		</form>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10" v-html="text"></div>

			<button
				class="btn btn-blue"
				@click.prevent="goTo"
			>
				{{buttonText}}
			</button>
			
		</modal>

	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';

	export default {
		data() {
			return {
				// banks: [],
				buttonText: 'Go To Dashboard',
				text: '',
				banksError: null,
				banksLoading: false,
				form: this.$options.basicForm([
					{name: 'phone_no', rules: 'required|phone'},
					{name: 'bvn', rules: 'required|number|length:11'},
					{name: 'emp_type', rules: 'required'},
					{name: 'salary', rules: 'required|number|min:50000'},
					{name: 'salary_day', rules: 'required|number'},
					{name: 'position', rules: 'required'},
					// {name: 'current_loan_facility', rules: 'required'},
					{name: 'account_no', rules: 'nullable|number|length:10'},
					{name: 'bank_name', rules: 'nullable'},
					{name: 'gender', rules: 'required'},
					{name: 'marital_status', rules: 'required'},
					{name: 'date_of_birth', rules: 'required'},
					// {name: 'id_type', rules: 'required'},
					{name: 'nin', rules: 'required'},
					{name: 'address', rules: 'required'},
					{name: 'lga', rules: 'required'},
					{name: 'state', rules: 'required'},
					{name: 'company_address', rules: 'required'},
				]),
				employmentTypes: [
					'Full Time',
					'Part Time',
					'Contract',
				],
				genders: [
					{ title: 'Male', value: 'male' },
					{ title: 'Female', value: 'female' }
				],
				idTypes: [
					{ title: 'National ID Card', value: 'national_id_card' },
					{ title: 'Driver’s License', value: 'drivers_license' },
					{ title: 'Permanent Voter’s Card', value: 'voters_card' },
					{ title: 'Nigerian International Passport', value: 'international_passport' },
				],
				maritalStatuses: [
					{ title: 'Single', value: 'single' },
					{ title: 'Engaged', value: 'engaged' },
					{ title: 'Married', value: 'married' },
					{ title: 'Divorced', value: 'divorced' },
					{ title: 'Widowed', value: 'widowed' },
				],
			}
		},
		computed: {
			...mapState(['banks']),
			bankOptions() {
				return this.banks.map((bank) => ({
					title: bank.name,
					value: bank.code,
				}));
			},
			bankAccountNeedsVerification() {
				return this.trialBankAccountNumber && this.trialBank;
			},
			bankAccountDoesntExist() {
				return this.bankAccountNeedsVerification && !this.user?.profile?.trial_bank_account_name;
			},
			bvnAlreadyProvided() {
				return !!this.user.profile?.bvn;
			},
			ninAlreadyProvided() {
				return !!this.user.profile?.nin
			},
			salaryAlreadyProvided() {
				return !!this.user.profile?.salary_day;
			},
			email() {
				return this.user.email;
			},
			firstName() {
				return this.user?.name;
			},
			isEmployed () {
				return this.user?.profile?.employment_status === 'employed'
			},
			lastName() {
				return this.user?.last_name;
			},
			daysOfTheMonth() {
				const days = [];

				for (var i = 0; i < 31; i++) {
					const day = i+1;
					const value = day;
					const title = day.toPosition();
					days.push({ title, value });
				}

				return days;
			},
			states() {
				return this.$store.state.states.map(state => state.name);
			},
			lgas() {
				return this.$store.state.states
				.find(state => state.name == this.form.data.state.value)
				?.locals.map(lga => lga.name) || [];
			},
			trialBankAccountNumber() {
				return this.user?.profile?.trial_account_no;
			},
			trialBank() {
				return this.$options.filters.bankName(this.user?.profile?.trial_bank_code, this.banks);
			},
			additionalPhone() {
				return this.user.profile?.additional_phone?.join(", ");
			},
			additionalPhoneProvided() {
				return !!this.user.profile?.additional_phone;
			},
		},
		mounted() {
			this.mapProfileData();
			if(!this.confirmDocuments()){
				this.buttonText = 'Go ahead';
				this.text = `<div class="inline-block">Profile was successfully updated.</div>
				<div class="inline-block">One more step to go.</div>
				<div class="inline-block">Upload your Bank statement, Government and Work ID.</div>`;
				return;
			}
			this.buttonText = 'Go To Dashboard';
			this.text = `Profile was successfully updated.`;
		},
		watch: {
			lgas(lgas) {
				const selectedLga = this.form.data.lga.value;
				if (lgas.indexOf(selectedLga) < 0) {
					this.form.data.lga.value = '';
				}
			},
		},
		methods: {
			...mapActions('session', ['updateUser']),

			goTo(){
				if (!this.confirmDocuments()) {
					this.$emit('changeTab', 'documents');
					this.$refs.modal.close();
					return;
				}
				this.$router.push({name: 'dashboard'});

			},
			mapProfileData() {
				// if (!this.user.profile) {
				// 	return false;
				// }

				for (let key in this.form.data) {
					this.form.data[key].value = this.user.profile?.[key] || this.user[key] || '';
				}

				if (!this.isEmployed) {
					this.form.data.emp_type.rules = 'nullable'
				}

			},
			async submit() {
				//this.googleEvent('Sign Up', 'update_profile')
				
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = null;
				
				this.form.loading = true;

				const data = JSON.stringify({
					user_id: this.user.id,
					...this.getFormData()
				})

				await this.sendRequest('personal.profile.update', {
					data,
					success: (response) => {
						this.updateUser({ profile: response.data.profile });
						this.mapProfileData();
						this.$refs.modal.open();
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			},
		}
	}
</script>
