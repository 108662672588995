<template>
  <div>
    <div class="card border border-blue-200 pt-10 pb-10 px-10 md:px-16 mb-8">
      <!-- <form @submit.prevent="submitGovtID" class="mb-10" key="govt_id"> -->
      <form @submit.prevent class="mb-10" key="govt_id">
        <div class="flex flex-row justify-between">
          <div class="text-sm mb-4">Government Issued ID</div>
          <!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
        </div>

        <sm-loader class="mb-20" v-if="documents.loading" />
        <div
          class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
          v-else-if="lastGovtId"
        >
          <div class="opacity-50 mb-4">Last Uploaded</div>
          <div class="flex flex-row items-center justify-between">
            <!-- <a
							:href="lastGovtId.url"
							target="_blank"
							class="underline mr-3"
							>{{ lastGovtId.filename }}</a
						> -->
            <button
              type="button"
              class="underline mr-3"
              @click="viewDocument(lastGovtId.id)"
            >
              {{ lastGovtId.filename }}
            </button>
            <!-- <span class="text-red-500" v-if="lastStatementIsOutdated">
							Your bank statement is outdated!
						</span> -->xxx
          </div>
        </div>

        <dropzone
          accept=".png, .jpg, .jpeg"
          v-model="govtIDForm.data.govt_id.value"
          :uploading="govtIDForm.loading"
          :uploaded="govtIDForm.success"
        ></dropzone>
        <div class="italic text-11 text-gray-700 -mt-8">
          Accept PNG, JPG, JPEG only
        </div>

        <template v-if="getFormError(govtIDForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ getFormError(govtIDForm) }}</span>
          </div>
        </template>

        <div class="text-right">
          <button
            type="button"
            class="btn btn-gray-soft"
            :disabled="!govtIDForm.preview"
            @click.prevent="previewGovtId"
          >
            Preview
          </button>
          <!-- <button
						type="submit"
						class="btn btn-blue"
						:disabled="!govtIDForm.data.govt_id.value"
					>
						Submit
					</button> -->
        </div>
      </form>

      <!-- <form @submit.prevent="submitStaffID" class="mb-10" key="staff_id"> -->
      <form @submit.prevent class="mb-10" key="staff_id" v-if="isEmployed">
        <div class="flex flex-row justify-between">
          <div class="text-sm mb-4">Work/Staff ID</div>
          <!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
        </div>

        <sm-loader class="mb-20" v-if="documents.loading" />
        <div
          class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
          v-else-if="lastWorkId"
        >
          <div class="opacity-50 mb-4">Last Uploaded</div>
          <div class="flex flex-row items-center justify-between">
            <!-- <a
							:href="lastWorkId.url"
							target="_blank"
							class="underline mr-3"
							>{{ lastWorkId.filename }}</a
						> -->
            <button
              type="button"
              class="underline mr-3"
              @click="viewDocument(lastWorkId.id)"
            >
              {{ lastWorkId.filename }}
            </button>
            <!-- <span class="text-red-500" v-if="lastStatementIsOutdated">
							Your bank statement is outdated!
						</span> -->
          </div>
        </div>

        <dropzone
          accept=".png, .jpg, .jpeg"
          v-model="staffIDForm.data.staff_id.value"
          :uploading="staffIDForm.loading"
          :uploaded="staffIDForm.success"
        ></dropzone>
        <div class="italic text-11 text-gray-700 -mt-8">
          Accept PNG, JPG, JPEG only
        </div>

        <template v-if="getFormError(staffIDForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ getFormError(staffIDForm) }}</span>
          </div>
        </template>

        <div class="text-right">
          <button
            type="button"
            class="btn btn-gray-soft"
            :disabled="!staffIDForm.preview"
            @click.prevent="previewStaffId"
          >
            Preview
          </button>
          <!-- <button
						type="submit"
						class="btn btn-blue"
						:disabled="!staffIDForm.data.staff_id.value"
					>
						Submit
					</button> -->
        </div>
      </form>

      <div class="inline-flex mb-10" v-if="expressVerification">
        <p class="text-black font-semibold text-13 text-left mt-2 mb-5">
          Verified with workplace email
        </p>
        <span class="flex h-3 w-3">
          <span
            class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3 bg-blue-500"
          ></span>
        </span>
      </div>

      <!-- <form @submit.prevent="submitBankStatement" key="bank_statement"> -->
      <form
        @submit.prevent
        key="bank_statement"
        v-if="!expressVerification || activatedProfile"
      >
        <template v-if="getFormError(bankStatementForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(bankStatementForm) }}</span>
          </div>
        </template>

        <div class="flex flex-row justify-between">
          <div class="text-sm mb-4">Bank Statement</div>
          <!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
        </div>

        <sm-loader class="mb-20" v-if="lastStatementLoading" />
        <div
          class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
          v-else-if="lastStatement"
        >
          <div class="opacity-50 mb-4">Last Uploaded</div>
          <div class="flex flex-row items-center justify-between">
            <!-- <a
							:href="lastStatement.url"
							target="_blank"
							class="underline mr-3"
							>{{ lastStatement.filename }}</a
						> -->
            <button
              type="button"
              class="underline mr-3"
              @click="viewDocument(lastStatement.id)"
            >
              {{ lastStatement.filename }}
            </button>
            <span class="text-red-500" v-if="lastStatementIsOutdated">
              Your bank statement is outdated!
            </span>
          </div>
        </div>

        <dropzone
          accept=".pdf"
          v-model="bankStatementForm.data.bank_statement.value"
          :uploading="bankStatementForm.loading"
          :uploaded="bankStatementForm.success"
        ></dropzone>

        <!-- <div class="text-right">
					<button
						type="submit"
						@click="submitDocument"
						class="btn btn-blue"
						:disabled="!bankStatementForm.data.bank_statement.value"
					>
						Submit
				</button>
				</div> -->
      </form>

      <form @submit.prevent class="mb-10" key="file">
        <div class="flex flex-row justify-between">
          <div class="text-sm mb-1">Additional Documents (Optional)</div>
          <!-- <a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a> -->
        </div>
        <div class="mb-5 text-gray-600 text-sm">
          This is only for specifically requested documents
        </div>

        <template v-if="customDocuments.length">
          <template v-for="(doc, index) in customDocuments">
            <div
              :key="index"
              class="border border-blue-200 flex flex-wrap items-center p-3 pl-0 pb-0 rounded mb-2"
            >
              <div class="mb-3 ml-3 mr-auto">
                <div class="font-medium">{{ doc.filename }}</div>
                <div class="text-sm text-blue-400 font-light">
                  <span>Uploaded at: </span>
                  <span class="font-medium text-blue-600">
                    {{ doc.created_at | dateFormat("D M dd, Y h:ia") }}
                  </span>
                </div>
              </div>
              <div class="mb-3 ml-3">
                <!-- <a
									:href="doc.url"
									target="_blank"
									class="btn btn-blue btn-sm"
								>
									View Document
								</a> -->
                <button
                  type="button"
                  class="px-5 py-3 rounded-sm text-sm inline-block bg-blue-500 hover:bg-blue-600 text-white mb-2"
                  @click="viewDocument(doc.id)"
                >
                  View Document
                </button>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <div class="alert alert-gray-soft mb-10">
            <span class="alert-icon">!</span>
            <span>No Custom Documents Uploaded.</span>
          </div>
        </template>

        <sm-loader class="mb-20" v-if="documents.loading" />

        <div class="mt-5">
          <div class="mb-3 text-gray-500 text-sm">Add New Document</div>
          <form-group
            v-model="customDocumentForm.data.title.value"
            :form="customDocumentForm"
            :data="customDocumentForm.data.title"
            name="title"
          >
            Document Title
          </form-group>

          <dropzone
            v-model="customDocumentForm.data.file.value"
            :uploading="customDocumentForm.loading"
            :uploaded="customDocumentForm.success"
          ></dropzone>
          <div
            class="text-sm text-red-500 mb-10 -mt-8"
            v-if="getFirstError('file', customDocumentForm)"
          >
            {{ getFirstError("file", customDocumentForm) }}
          </div>
          <div class="italic text-11 text-gray-700 -mt-8" v-else>
            <div class="block">Accepts All Files</div>
            <div class="block">Max File Size 50MB</div>
          </div>

          <template v-if="getFormError(customDocumentForm)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon mr-3">!</span>
              <span>{{ getFormError(customDocumentForm) }}</span>
            </div>
          </template>

          <div class="text-right">
            <a
              :href="customDocumentForm.preview"
              class="btn btn-gray-soft"
              target="_blank"
              :disabled="!customDocumentForm.preview"
            >
              Preview
            </a>
            <!-- <button
							type="submit"
							class="btn btn-blue"
							:disabled="!customDocumentForm.data.file.value"
						>
							Submit
						</button> -->
          </div>
        </div>
      </form>

      <div class="text-right">
        <button
          type="submit"
          @click="submitDocument"
          class="btn btn-blue"
          :disabled="
            (!bankStatementForm.data.bank_statement.value &&
              !staffIDForm.data.staff_id.value &&
              !govtIDForm.data.govt_id.value &&
              !customDocumentForm.data.file.value) ||
              uploading
          "
        >
          <template v-if="uploading">Uploading...</template>
          <template v-else>
            Submit
          </template>
        </button>
      </div>
    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Document was successfully uploaded.
      </div>

      <router-link :to="{ name: 'dashboard' }" class="btn btn-blue btn-md">
        Go to Dashboard
      </router-link>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      uploading: false,
      lastStatementLoading: false,
      lastStatement: null,
      documents: this.$options.resource([]),
      bankStatementForm: this.$options.basicForm([
        { name: "bank_statement", value: null }
      ]),
      staffIDForm: this.$options.basicForm(
        [{ name: "staff_id", value: null }],
        { preview: null }
      ),
      govtIDForm: this.$options.basicForm([{ name: "govt_id", value: null }], {
        preview: null
      }),
      customDocumentForm: this.$options.basicForm(
        ["title", { name: "file", value: null }],
        { preview: null }
      )
    };
  },
  computed: {
    customDocuments() {
      return this.documents.data.filter(doc => doc.type === "custom");
    },
    employmentStatus() {
      return this.user?.profile?.employment_status || "N/A";
    },
    isEmployed() {
      return this.employmentStatus == "employed";
    },
    lastStatementIsOutdated() {
      return false;
      // if (!this.lastStatement) {
      // 	return false;
      // }

      // const date = (new Date(this.lastStatement.created_at)).getTime();
      // const now = (new Date()).getTime();
      // const diff = now - date;

      // console.log(date)
      // console.log(now)
      // console.log(diff)

      // return diff >= 2592000;
    },
    expressVerification() {
      return this.user?.profile?.verify_method === "express";
    },
    bankStatements() {
      return this.documents.data.filter(
        document => document.type == "bank_statement"
      );
    },
    activatedProfile() {
      return this.user?.profile?.status === "activated";
    },
    govtIds() {
      return this.documents.data.filter(document => document.type == "govt_id");
    },
    lastGovtId() {
      return this.govtIds[this.govtIds.length - 1];
    },
    workIds() {
      return this.documents.data.filter(document => document.type == "work_id");
    },
    lastWorkId() {
      return this.workIds[this.workIds.length - 1];
    }
  },
  mounted() {
    this.getDocuments();
    this.getLastStatement();
  },
  watch: {
    "govtIDForm.data.govt_id.value"(file) {
      if (!file) {
        return;
      }

      this.govtIDForm.preview = null;

      const reader = new FileReader();

      reader.onload = event => {
        this.govtIDForm.preview = event.target.result;
      };

      reader.readAsDataURL(file);
    },
    "staffIDForm.data.staff_id.value"(file) {
      if (!file) {
        return;
      }

      this.staffIDForm.preview = null;

      const reader = new FileReader();

      reader.onload = event => {
        this.staffIDForm.preview = event.target.result;
      };

      reader.readAsDataURL(file);
    },
    "customDocumentForm.data.file.value"(file) {
      if (!file) {
        return;
      }

      this.customDocumentForm.preview = null;

      const reader = new FileReader();

      reader.onload = event => {
        this.customDocumentForm.preview = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  },
  methods: {
    ...mapActions("session", ["updateUser"]),

    async getDocuments() {
      this.documents.loading = true;

      await this.$get({
        url: `${this.$baseurl}/staff/documents/all/${this.user.id}`,
        headers: this.headers,
        success: response => {
          this.documents.data = response.data.docs;
        },
        error: error => {
          this.documents.error = error;
        }
      });

      this.documents.loading = false;
    },
    async getLastStatement() {
      this.lastStatementLoading = true;

      await this.$get({
        url: `${this.$baseurl}/staff/documents/bank_statement/last/${this.user.id}`,
        headers: this.headers,
        success: response => {
          this.lastStatement = response.data.bank_statement;
        }
        // error: error => {
        // 	this.banksError = error;
        // }
      });

      this.lastStatementLoading = false;
    },
    mapProfileData() {
      for (let key in this.form.data) {
        this.form.data[key].value =
          this.user.profile?.[key] || this.user[key] || "";
      }
    },
    previewGovtId() {
      const preview = window.open();

      preview.document.body.innerHTML = `<img src="${this.govtIDForm.preview}">`;
    },
    previewStaffId() {
      const preview = window.open();

      preview.document.body.innerHTML = `<img src="${this.staffIDForm.preview}">`;
    },
    previewCustomDocument() {
      window.open(this.customDocumentForm.preview);
    },
    async submitDocument() {
      this.uploading = true;
      await this.submitGovtID();
      await this.submitStaffID();
      await this.submitBankStatement();
      await this.submitCustomDocument();
      await this.$root.loadSession();
      this.uploading = false;
      this.bankStatementForm.loading = false;
    },
    async submitGovtID() {
      if (!this.validateForm(this.govtIDForm)) {
        return false;
      }

      this.govtIDForm.error = null;

      this.govtIDForm.loading = true;

      const data = new FormData();

      data.append("govt_id", this.govtIDForm.data.govt_id.value);

      await this.$post({
        url: `${this.$baseurl}/staff/documents/govt_id/${this.user.id}`,
        data,
        headers: {
          ...this.headers,
          "Content-Type": "application/json"
        },
        success: () => {
          this.$refs.modal.open();
          this.govtIDForm = this.resetForm(this.govtIDForm);
          this.getDocuments();
        },
        error: error => {
          this.govtIDForm.error = error;
          this.mapFormErrors(this.govtIDForm, error?.response?.data?.errors);
        }
      });

      this.govtIDForm.loading = false;
    },

    async submitStaffID() {
      if (!this.validateForm(this.staffIDForm)) {
        return false;
      }

      this.staffIDForm.error = null;

      this.staffIDForm.loading = true;

      const data = new FormData();

      data.append("work_id", this.staffIDForm.data.staff_id.value);

      await this.$post({
        url: `${this.$baseurl}/staff/documents/work_id/${this.user.id}`,
        data,
        headers: {
          ...this.headers,
          "Content-Type": "application/json"
        },
        success: () => {
          this.$refs.modal.open();
          this.staffIDForm = this.resetForm(this.staffIDForm);
          this.getDocuments();
        },
        error: error => {
          this.staffIDForm.error = error;
          this.mapFormErrors(this.staffIDForm, error?.response?.data?.errors);
        }
      });

      this.staffIDForm.loading = false;
    },
    async submitCustomDocument() {
      if (
        !this.customDocumentForm.data.file.value ||
        !this.validateForm(this.customDocumentForm)
      ) {
        return false;
      }

      this.customDocumentForm.error = null;

      this.customDocumentForm.loading = true;

      const data = new FormData();
      data.append("title", this.customDocumentForm.data.title.value);
      data.append("file", this.customDocumentForm.data.file.value);

      await this.sendRequest("personal.documents.custom", {
        data,
        success: () => {
          this.$refs.modal.open();
          this.customDocumentForm = this.resetForm(this.customDocumentForm);
          this.getDocuments();
        },
        error: error => {
          this.customDocumentForm.error = error;
          this.mapFormErrors(
            this.customDocumentForm,
            error?.response?.data?.errors
          );
        }
      });

      this.customDocumentForm.loading = false;
    },
    async submitBankStatement() {
      if (!this.validateForm(this.bankStatementForm)) {
        return false;
      }

      this.bankStatementForm.error = null;

      this.bankStatementForm.loading = true;

      const data = new FormData();

      data.append(
        "bank_statement",
        this.bankStatementForm.data.bank_statement.value
      );

      await this.$post({
        url: `${this.$baseurl}/staff/documents/bank_statement/${this.user.id}`,
        data,
        headers: {
          ...this.headers,
          "Content-Type": "application/json"
        },
        success: () => {
          this.$refs.modal.open();
          this.bankStatementForm = this.resetForm(this.bankStatementForm);
          this.getDocuments();
          this.getLastStatement();
        },
        error: error => {
          console.log(error);
          this.bankStatementForm.error = error;
          this.mapFormErrors(
            this.bankStatementForm,
            error?.response?.data?.errors
          );
        }
      });

      await this.$root.loadSession();
    },
    async viewDocument(documentId) {
      await this.$get({
        url: `${this.$baseurl}/staff/documents/${documentId}`,
        headers: {
          ...this.headers,
          "Content-Type": "application/json"
        },
        success: response => {
          window.open(
            response.data.url,
            "_blank" // <- This is what makes it open in a new window.
          );
        },
        error: error => {
          this.workIdForm.error = error;
          this.mapFormErrors(this.workIdForm, error?.response?.data?.errors);
        }
      });

      this.workIdForm.loading = false;
    }
  }
};
</script>
<style scoped>
.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
